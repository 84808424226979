<template>
  <div style="background-color: #f5faff; z-index: 0; height: 100vh">
    <NavBar
    style="max-height: 50px;margin-bottom: 80px;"
    :username="currentUserName"
    :pageName="pageName"
    class="docVideoCallNavbar"
    ></NavBar>
    <div>
      <v-row justify="center">
        <v-dialog v-model="appointmentTimeOverModal" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
             Teleconsultation time is up !
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogAppointmentTimeOverModal">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
          <v-dialog
            v-model="presc_dialog"
            persistent
            max-width="500"
          >
            <v-card>
              <v-card-title class="text-h5" style="word-break: break-word !important">
                CONFIRMATION FOR SUBMISSION
              </v-card-title>
              <v-card-text>Once a prescription has been submitted, it cannot be changed.</v-card-text>
              <v-card-actions>
                <v-btn depressed
                color="warning"
                @click="presc_dialog = false">
                Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
:disabled="buttonDisableFlag"
                  color="primary"
                  depressed
                  @click="save_prescription()"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="appointmentStartMessageModal" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
              Please join 5 minutes appointment time!
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialogAppointmentStartMessageModal">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <div>
      <v-row justify="center">
        <v-dialog v-model="exitModal" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break:normal">
             Prescription has been saved successfully.<br>Do you want to Exit?
           </v-card-title>
           <v-card-actions>
            <v-btn color="primary" depressed @click="closeExitModal">
              OK
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="warning" depressed @click="redirectToViewAppointment">
              Cancel
            </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <div>
      <v-row>
        <v-dialog v-model="prescriptionDialog" persistent max-width = "50%">
          <v-card style="padding: 40px 30px">
            <v-row>
              <v-col cols="12">
                <div style="display: flex; flex-direction: row; justify-content: space-between;  margin-top: 10px">
                  <div style="display: flex; flex-direction: row;">
                   <div> <p>Booking ID:</p></div>
                   <div><span style=" font-weight: 600">{{
                      view_prescription_object.appointment_reference_number
                    }}</span></div>
                  </div>
                  <div>
                     <v-btn
                      icon
                      color="red"
                      class="text--white"
                      v-on:click="closePrescriptionDialogue"
                    >
                      <h2>X</h2>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row style = "margin: 10px 0px;">
              <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left">
                Presenting Symptoms
              </v-col>
              <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.presenting_symptoms}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                Past medical history
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.medicalHistory}}</p>
              </v-col>
              <v-col style="border-style: hidden groove hidden hidden" cols="4" lg="4" sm="4" md="4" xl="4">
                Past surgical history
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.surgicalHistory}}</p>
              </v-col>
              <v-col cols="4" lg="4" sm="4" md="4" xl="4">
                Family history
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="family_history" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.familyHistory}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                Regular taking/ Current medicine
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.current_taking_medicines}}</p>
              </v-col>
              <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4">
                Known Drug Allergies
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="allergies" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.allergies}}</p>
              </v-col>
              <v-col cols="4" lg="4" sm="4" md="4" xl="4">
                Vaccination History
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.vaccinationHistory}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                Diagnosis
              </v-col>
              <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.diagnosed_with}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col cols="3" sm="3" md="3" align="left">
                Treatment
              </v-col>
              <v-col cols="12" sm="12" md="12" align="left">
                <table style=" width: 100%; background-color: #F1F6FF;">
                  <tr align="left" style="padding: 15px;">
                    <th style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</th>
                    <th style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</th>
                    <th style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</th>
                    <th style="font-size:16px; line-height: 20px; padding: 5px;">Duration</th>
                  </tr>
                  <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in view_prescription_object.prescription" :key=index>
                     <td style="padding:5px;font-weight: 500">{{item.medicine}}</td>
                     <td style="padding:5px;font-weight: 500">{{item.dosage}}</td>
                     <td style="padding:5px;font-weight: 500">{{item.frequency}}</td>
                     <td style="padding:5px;font-weight: 500">{{item.duration}}</td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                Investigations needed
              </v-col>
              <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                <p class="variable">{{view_prescription_object.investigationNeeded}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                To Come after
              </v-col>
              <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                <!-- <v-select disabled background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                <p class="variable">{{view_prescription_object.next_followup_date}}</p>
              </v-col>
            </v-row>
            <v-divider/>
            <v-row  style = "margin: 10px 0px;">
              <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                Refer To
              </v-col>
              <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                <!-- <v-select disabled background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/> -->
                <p class="variable">{{selected_specialization.specialization_name}}</p>
              </v-col>
              <v-col cols="3" lg="3" sm="3" md="3" xl="3" v-if="selected_specialization.specialization_name == 'Others'">
                <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="others" solo rows=1></v-textarea> -->
                <p class="variable">{{others}}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <v-app style="background-color: #f5faff" align="center">
      <v-row>
        <v-col cols="3" sm="3" lg="3" md="3" style="top: 60px; height: 90vh;" class="videoAndPrescriptionContainer">
          <v-row v-if="!call_ended">
            <v-col cols="1">
            </v-col>
            <v-col  cols="11"
              sm="11">

              <div id="video-container" class="row remote_video_container">
                <div id="remoteTrack"></div>
                <div id="localTrack"></div>
              </div>
              <div style="display:flex; flex-direction: row;justify-content: space-evenly; margin-top: 30px;" v-if="callConnected">
                <div style="margin: 2px;" v-if="videoBtnFlag && deviceIDList.length > 1">
                <v-btn
                    elevation="8"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;"
                    @click="switchCamera(activeRoom)"
                    icon
                    ><img
                      src="https://d3vsnl8idgwrlw.cloudfront.net/switchCamera.svg"
                  /></v-btn>
                  </div>
                <div style="margin: 2px;" v-if="videoBtnFlag">

                  <v-btn
                    elevation="8"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;"
                    @click="turnVideoOff"
                    icon
                    ><img
                      src="https://d3vsnl8idgwrlw.cloudfront.net/CameraOn.svg"
                  /></v-btn>
                </div>
                <div style="margin: 2px;" v-else>
                  <v-btn
                    elevation="8"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;"
                    @click="turnVideoOn"
                    icon
                    ><img
                      src="https://d3vsnl8idgwrlw.cloudfront.net/CameraOff.svg"
                  /></v-btn>
                </div>
                <div style="margin: 2px;" v-if="audioBtnFlag">
                  <v-btn
                    elevation="8"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;"
                    @click="turnAudioOff"
                    id="muteAudio"
                    icon
                    ><img src="https://d3vsnl8idgwrlw.cloudfront.net/micOn.svg"
                  /></v-btn>
                </div>
                <div v-else style="margin: 2px;">
                  <v-btn
                    elevation="8"
                    style="  background: #FFFFFF;border-radius: 8px; color:white;"
                    @click="turnAudioOn"
                    icon
                    ><img src="https://d3vsnl8idgwrlw.cloudfront.net/micOff.svg"
                  /></v-btn>
                </div>
                <div style="margin:2px">
                  <v-btn elevation="8"  style="background: #EB5757; border-radius: 8px; position: relative; bottom: 0; margin-bottom: 30px;" @click="endTwilioVideoCall" icon ><img src="https://d3vsnl8idgwrlw.cloudfront.net/leaveCall.svg" ></v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else style="margin-top: 50%; margin-left: 30%;">
            <div align="center" v-show="false">
              Call disconnected
            </div>
            <div align="center" style="margin-top: 30%">
              <p>Call has been disconnected</p>
            </div>
            <div align="center" v-show="false">
              <v-btn class="primary" style="margin-top: 30%;">Reconnect</v-btn>
            </div>
          </v-row>
        </v-col>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="isLoading"
          style="position: fixed;top: 50%;left: 50%;"
        ></v-progress-circular>
        <v-col cols="9" sm="9" lg="9" md="9" style="top: 60px; width: 70vw;overflow: scroll; height: 80vh;"  align="center" v-if="!isLoading" class="PrescriptionContainer">
          <h3
            class="DocVideoCalltitleClass"
            style="
            text-align: left;
            margin-top: 30px;
            font-weight: 800;
            letter-spacing: 0.08em;
            margin-bottom: 10px;
            margin-left: 10px;
            "
            >
            Patient Details
          </h3>
          <div style="margin-left: 10px;">
            <v-card class="patientInfoCard">
              <div style="display: flex; flex-direction: column">
                <div align="left" style="padding: 24px 32px 0 32px">
                  <p style="font-weight: bold; margin-bottom: 3px">
                    BOOKING ID: {{ appointment_reference_number }}
                  </p>
                </div>
                <v-divider />
                <div style="padding: 24px 32px 0 32px;">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;">
                    <div
                      style="
                        display: flex;
                        flex-direction: row;">
                      <div>
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </div>
                      <div style="display: flex; flex-direction: column; margin-left: 10px;" align="left">
                        <div style="color: #828282">Patient Name</div>
                        <div style="font-weight: bold" align="left">
                          {{ nameOfPatient }}
                        </div>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-right: 50px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div align="center">
                            <img
                              style="width: 25px; height: 25px"
                              src="https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">Date</div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                              <DateRendered :dateVal="dateOfAppointment"> </DateRendered>

                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          margin-left: 20px;
                        "
                      >
                        <div style="display: flex; flex-direction: row">
                          <div>
                            <img
                              style="width: 25px; height: 25px"
                              src="https://d3vsnl8idgwrlw.cloudfront.net/clock.svg"
                            />
                          </div>
                          <div style="margin: 1px; color: #828282">
                            Time Slot
                          </div>
                        </div>
                        <div style="display: flex; flex-direction: row">
                          <div style="font-weight: normal">
                            <TimeRender :timeSlot="timeSlot"> </TimeRender>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  padding-left: 32px;
                  padding-top: 10px;
                "
                align="left"
              >
                <p style="color: #828282;  font-weight: 600;">Patient Details</p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-right: 5%;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Age:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ age }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/gender.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Gender:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_gender " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_gender }}</span>
                      <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_gender }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/height.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Height:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_height" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_height }}</span>

                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_height }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/weight.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Weight:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.customer_weight " style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.customer_weight }}</span>
                       <span  v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{ patientDetails.dependent_weight }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/alcoholicIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">
                        Alcoholic:
                      </p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.alcoholic" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.alcoholic
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_alcoholic
                      }}</span>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row;">
                    <div>
                      <img
                        style="width: 25px; height: 25px"
                        src="https://d3vsnl8idgwrlw.cloudfront.net/smokeIcon.svg"
                      />
                    </div>
                    <div>
                      <p style="margin-top: 3px; margin-left: 5px; color: #828282; font-size: 14px; line-height: 20px; font-weight: 400;">Smoker:</p>
                    </div>
                    <div style="margin-left: 5px">
                      <span v-if="patientDetails.smoker" style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.smoker
                      }}</span>
                      <span v-else style="font-size: 16px; font-weight: 400; line-height: 20px;">{{
                        patientDetails.dependent_smoker
                      }}</span>
                    </div>
                  </div>

                </div>
                <v-divider style="width:95%" />
                <p style="color: #828282; font-weight: 600; padding-top:30px;">Description</p>
                <div style="font-weight: normal" align="left">
                  <p>
                    {{ description }}
                  </p>
                </div>
                <br>
                <div v-if="allAttachments.length > 0">
                  <p style="color: #828282; font-weight: 600; display:flex; justify-content:flex-start;">View Attachment</p>
                  <div style="display: flex; flex-direction: column; flex-wrap:wrap;">
                    <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                      <v-chip @click="ViewFile(attachments)" target="_blank">
                        {{attachments.split("/")[attachments.split("/").length-1]}}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <v-card
            class="docVideoCallMobilePatientCard"
              style="
                box-shadow: 0px 8px 20px 0px #0547891a;
                border: 1px solid #e4f5ff;
                border-radius: 8px;
              "

            >
              <div style="margin: 15px">
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-row no-gutters>
                      <v-col cols="2" sm="2" style="text-align: left">
                        <img
                          src="https://img.icons8.com/color/50/000000/user.png"
                        />
                      </v-col>
                      <v-col cols="7" sm="5">
                        <h4 align="left" style="font-weight: 300;font-size: 0.7em">
                          Patient Name
                        </h4>
                        <div
                          style="font-weight: 600; font-size: 0.7em"
                          align="left"
                        >
                          {{ nameOfPatient }}
                        </div>
                      </v-col>
                      <!-- <v-col class="iconDiv" style="padding-left:0px">
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/family_edit.svg"
                          @click="editDependent(family_member._id)"
                        />
                      </v-col>
                      <v-col style="padding-right: 10px;" >
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/family_delete.svg"
                          @click="
                            show_confirmation(
                              family_member._id,
                              family_member.dependent_name
                            )
                          "
                        />
                      </v-col> -->
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="MobileLayout">
                  <p style="color: #828282; font-weight: 600; padding-left: 12px">Description</p>
                </v-row>
                <v-row class="MobileLayout">
                  <div style="font-weight: normal; padding: 0px 12px 5px 12px;" align="left">
                    <p>
                      {{ description }}
                    </p>
                  </div>
                </v-row>
                <v-row class="MobileLayout">

                  <v-col cols="6" sm="6" class="DocVideoCallcols6Class">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Age: {{ age }}</span
                        >
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/gender.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.customer_gender "
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Gender: {{ patientDetails.customer_gender }}
                        </span>
                        <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Gender: {{ patientDetails.dependent_gender }}
                        </span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/weight.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          v-if="patientDetails.customer_weight "
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Weight: {{ patientDetails.customer_weight }} Lbs</span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Weight:{{ patientDetails.dependent_weight }} Lbs</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/height.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.customer_height"
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Height: {{ patientDetails.customer_height }} </span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Height: {{ patientDetails.dependent_height }} </span>
                      </div>
                    </div>
                  </v-col>
                                    <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/alcoholicIcon.svg"
                        />
                      </div>
                      <div align="left">
                        <span
                          v-if="patientDetails.alcoholic "
                          style="
                            font-size: 10.4px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Alcoholic: {{ patientDetails.alcoholic }}</span>
                          <span
                          v-else
                          style="
                            font-size: 10.4px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Alcoholic: {{ patientDetails.dependent_alcoholic }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <div style="display: flex; flex-direction: row">
                      <div>
                        <img
                          src="https://d3vsnl8idgwrlw.cloudfront.net/smokeIcon.svg"
                        />
                      </div>
                      <div>
                        <span
                          v-if="patientDetails.smoker"
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Smoker: {{ patientDetails.smoker }} </span>
                          <span
                          v-else
                          style="
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 20px;
                            padding-left: 10px;
                          "
                        >
                          Height: {{ patientDetails.dependent_smoker }} </span>
                      </div>
                    </div>
                  </v-col>
                  <div v-if="allAttachments.length > 0">
                  <p style="color: #828282; font-weight: 600;" class="attachmentsTitleClass">View Attachment</p>
                  <div style="display: flex; flex-direction: row wrap;" class="attachmentDataClass">
                    <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                      <v-chip @click="ViewFile(attachments)" target="_blank">
                        {{attachments.split("/")[attachments.split("/").length-1]}}
                      </v-chip>
                    </div>
                  </div>
                </div>
                </v-row>

              </div>
            </v-card>
          </div>
          <v-tabs
            v-model="tab"
            background-color="primary"
            class="docVideoCallTab"
            style="margin-top: 2%; margin-bottom: 1%; margin-left: 10px;">
            <v-tab v-for="item in items" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items style="margin-left: 10px;" v-model="tab">
            <v-tab-item>
              <v-card style="padding: 32px 24px;">
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left" class="persistingSymptomsClass presentingSysmptoms">
                    Presenting Symptoms
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="persistingSymptomsClass presentingSysmptoms">
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Past medical history
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Past surgical history
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Family history
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="family_history" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Regular taking/ Current medicine
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Known Drug Allergies
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="allergies" solo rows=1></v-textarea>
                  </v-col>
                  <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docVideoCallMedicalDetails">
                    Vaccination History
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docVideoCallMedicalDetails">
                    Diagnosis <span style="color:red">*</span>
                  </v-col>
                  <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                    <v-textarea background-color="#F1F6FF" :disabled="!prescription_fill_flag" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" sm="3" md="3" align="left" class="docVideocallTreatmentTitle">
                    Treatment
                  </v-col>
                  <v-col cols="12" sm="12" md="12" align="left" class="tableCols">
                   <table style=" width: 100%; background-color: #F1F6FF;" class="prescriptionTableClass">
                      <tr align="left" style="padding: 15px;">
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</td>
                        <td style="font-size:16px; line-height: 20px; padding: 5px;">Duration</td>
                      </tr>
                      <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.medicine"/></td>
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.dosage"/></td>
                        <td style="padding:5px;"><v-select :disabled="!prescription_fill_flag" outlined background-color="white" solo :items="frequency_list" v-model="item.frequency"></v-select></td>
                         <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.duration"/></td>
                        <td class="addRemoveButton"><img v-if="index == prescription_object.length-1" src="https://d3vsnl8idgwrlw.cloudfront.net/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                          <img v-else-if="index < prescription_object.length" src="https://d3vsnl8idgwrlw.cloudfront.net/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"></td>

                      </tr>
                    </table>
                    <table style=" width: 100%; background-color: #F1F6FF; margin-top:18px" class="PrescriptionTableMobileLayout" v-for="(item,index) in prescription_object" :key=index>
                      <tr align="left">
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Medicine</td>
                      </tr>
                      <tr >
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.medicine"/></td>
                      <tr>
                      <table >
                      <tr>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Dosage</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Frequency</td>
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Duration</td>
                      </tr>
                      <tr >
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.dosage"/></td>
                        <td style="padding:5px;"><v-select outlined background-color="white" solo :items="frequency_list" v-model="item.frequency"></v-select></td>
                        <td style="padding:5px;"><v-text-field :disabled="!prescription_fill_flag" class="textfield_class" solo outlined
                         v-model="item.duration"/></td>
                      </tr>
                         </table>
                      </tr>
                      </tr>
                      <tr>
                        <td>
                          <!-- <img v-if="index == prescription_object.length-1" src="https://d3vsnl8idgwrlw.cloudfront.net/add_book_appointment.svg" style="margin-top:-50%;" @click="add_rec">
                          <img v-else-if="index < prescription_object.length" src="https://d3vsnl8idgwrlw.cloudfront.net/delete.svg" style="height: 40px; width 40%; margin-top: -50%;" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)"> -->
                          <v-btn width="100%" color="primary" v-if="index == prescription_object.length-1" @click="add_rec" >
                            + Add More
                          </v-btn>
                          <v-btn width="100%" color="warning" v-else-if="index < prescription_object.length" @click="remove_rec(item.medicine,item.dosage,item.frequency,item.duration)" >
                            Delete Row
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docVideoCallMedicalDetails">
                    Suggestions
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12" xl="12" class="docVideoCallMedicalDetails">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="suggestions"  solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3"  align="left" class="docVideoCallMedicalDetails">
                    Investigations needed
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12" xl="12" class="docVideoCallMedicalDetails">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="investigations_needed" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docVideoCallMedicalDetails">
                    To Come after
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docVideoCallMedicalDetails">
                    <!-- <v-select background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                    <!-- <input type="date" v-model="come_after"   background-color="#F1F6FF" style="background-color:#F1F6FF; padding:10px; border-radius: 5px;"/> -->
                    <!-- <input type="date" v-model="come_after" background-color="#F1F6FF"/> -->
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :disabled="!prescription_fill_flag"
                                v-model="followUpDate"
                                label="Follow Up Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :disabled="!prescription_fill_flag"
                              :min="new Date().toISOString().substring(0, 10)"
                              v-model="followUpDate"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docVideoCallMedicalDetails">
                    Refer To
                  </v-col>
                  <v-col cols="3" lg="3" sm="3" md="3" xl="3" class="docVideoCallMedicalDetails">
                    <v-select background-color="#F1F6FF" solo v-model="selected_specialization" :disabled="!prescription_fill_flag" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/>
                  </v-col>
                  <v-col lg="7" sm="7" md="7" xl="7" v-if="selected_specialization.specialization_name == 'Others'">
                    <v-textarea background-color="#F1F6FF" class="textarea_class" :disabled="!prescription_fill_flag" v-model="others" solo rows=1></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" style="padding-bottom: 100px;">
                    <v-btn color = primary @click="submit_prescription()" class="saveBtnClass" :disabled="!prescription_fill_flag"> Save Prescription & Exit </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <div>
                <div style=" margin-right: 2%; border-radius: 12px;">
                  <div style="display: flex; flex-dirextion: row; flex-wrap: wrap;padding-bottom: 50px;">
                    <v-card width="20vw" style="border-radius: 12px; margin: 2%" v-for="prescription in prescriptionDetails" :key="prescription.appointment_reference_number" >
                      <div style=" display: flex; flex-direction: column; justify-content: left; margin-top: 15px !important; " >
                        <div>
                          <h4
                          align="left"
                          style="padding-top: 10px; margin-left: 20px"
                          v-if="prescription.diagnosed_with != ''"
                          >
                          {{ prescription.diagnosed_with }}
                          </h4>

                          <h4
                          align="left"
                          style="padding-top: 10px; margin-left: 20px"
                          v-else
                          >
                          NA
                          </h4>
                        </div>
                        <div
                          style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          "
                          >
                          <div
                            style="
                            display: flex;
                            flex-direction: column;
                            padding: 2px;
                            margin-top: 5%;
                            margin-left: 5%;
                            "
                            >
                            <div>
                              <h6
                              style="
                              margin-top: 20%;
                              color: #828282 !important;
                              "
                              align="left"
                              >
                                Doctor Name
                              </h6>
                            </div>
                            <div>
                              <p
                              style="margin-top: 2px; font-weight: 700; font-size: 0.8em"
                              align="left"
                              >
                                {{ prescription.doctor_name }}
                              </p>
                            </div>
                          </div>
                          <div>
                            <div
                              style="
                              display: flex;
                              flex-direction: row;
                              margin-top: 35%;
                              margin-right: 10px;
                              "
                              >
                              <div>
                                <img
                                style="width: 25px; height: 25px"
                                src="https://d3vsnl8idgwrlw.cloudfront.net/calendar.svg"
                                />
                              </div>
                              <div style="margin-top: 3px; margin-left: 5px;font-size: 0.8em">
                                <strong>{{
                                  prescription.date_of_appointment
                                }}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="margin-top: 10px; margin-bottom: 10px">
                          <v-btn
                          color="primary"
                          width="85%"
                          @click="viewPrescription(prescription._id)"
                          style="border-radius: 8px; bottom: 10px"
                          >
                          View Prescription
                          </v-btn>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import DateRendered from "../Customer/dateRender.vue";
import TimeRender from "../Customer/TimeRender.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';
const Twilio = require("twilio-video");
export default {
  name: "DoctorVideoCall",
  components: {
    NavBar,
    DateRendered,
    TimeRender
  },
  data() {
    return {
      buttonDisableFlag: false,
      countDownTimer : "",
      currentUser: "",
      currentUserName: "",
      pageName: "DoctorVideoCall",
      booking_id: "",
      doctor_id: "",
      patientDetails: "",
      appointment_url: "",
      age: "",
      appointmentDetails: "",
      allAttachments: "",
      specialization: "",
      patient_id: "",
      nameOfPatient: "",
      tab: null,
      prescriptionDetails: "",
      items: [
      { tab: "Write Prescription", content: "Tab 1 Content" },
      { tab: "Prescription History", content: "Tab 2 Content" },
      ],
      appointment_reference_number: "",
      age: "",
      prescriptionId: "",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      need_follow_up: false,
      exitModal:false,
      exams: "",
      suggestions: "",
      current_taking_medicines: "",
      diagnosed_with: "",
      allergies: "",
      selected: [],
      date_of_appointment:"",
      isLoading : false,
      appointmentTimeOverModal: false,
      showTimer : false,
      appointmentStartMessageModal: false,
      timeSlot:"",
      dateOfAppointment:"",
      presc_dialog: false,
      description:"",
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      frequency_list: ["OD","CM","HS","BD","TDS","QID","PRN"],
      follow_up_list: ["One Week", "Two week", "One Month", "Two Month","Three Month","Not Required"],
      come_after: "",
      others: "",
      specialization_list: [],
      selected_specialization: "",
      followUpDate: "",
      prescriptionDialog: false,
      view_prescription_object: "",
      view_selected_prescription: "",
      sessionId:"",
      sessionToken:"",
      publisherData:"",
      videoStatus: true,
      audioStatus : true,
      videoBtnFlag: true,
      audioBtnFlag: true,
      intervalOne:"",
      call_ended: false,
      current_epoch_time: "",
      sessionDetails:"",
      twilioMeetLink: null,
      prescription_fill_flag: true,
      twilioRoomName: null,
      container: null,
      activeRoom: null,
      callConnected: false,
      remoteParticipant:null,
      menu: false,
      deviceIDList:[],
      deviceID:"",
      currentCamIndex:0,
      connectTrack:"",
      currentFacingMode:"user"
    };
  },
  beforeRouteLeave: function(to, from, next) {
    from = this.sessionId;
    //var sessionDetails = OT.initSession(process.env.VUE_APP_OPEN_TOK_API_KEY, from);
    this.endTwilioVideoCall();

    next();
  },

  mounted() {
      const events = [
        "pagehide",
        "unload"
      ];
      const eventLogger = event => {
              switch (event.type) {
              case "pagehide":
              this.endTwilioVideoCall()
              break;
              default:
              this.endTwilioVideoCall()
              break;
          }
        };
      events.forEach(eventName =>
          window.addEventListener(eventName, eventLogger)
        );
    // navigation testing ends
    this.isLoading = true
    document.title = "Video Consultation"
    this.currentUser = this.$cookies.get("doctorToken");
    var presc_dets = "";
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var params = this.$route.params;
      this.booking_id = params["booking_id"];
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        bookingId: this.booking_id,
        page: 1,
        limit: 8
      };
      axios_auth_instance_doctor.post("/doctorVideoCallPageTwilio", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.doctor_data.doctor_name;
        this.doctor_id = successResponse.data.doctor_data._id;
        this.current_epoch_time = successResponse.data.current_epoch_time;
        this.appointmentDetails = successResponse.data.appointment_data;
        if(this.appointmentDetails.booking_epoch_time - this.current_epoch_time > 0){
          this.prescription_fill_flag = false;
        }
        this.appointment_reference_number = this.appointmentDetails.appointment_reference_number;
        this.patient_id = this.appointmentDetails.booked_for_id;
        this.nameOfPatient = this.appointmentDetails.booked_for_name;
        this.dateOfAppointment=this.appointmentDetails.date_of_appointment;
        this.specialization = this.appointmentDetails.specialization_name;
        this.timeSlot = this.appointmentDetails.time_slot;
        this.description = this.appointmentDetails.description;
        this.allAttachments = this.appointmentDetails.file_attachment;
        this.patientDetails = successResponse.data.patient_data;
        this.twilioMeetLink = successResponse.data.twilio_token;
        this.twilioRoomName = this.appointmentDetails.meeting_link;
        this.current_epoch_time = successResponse.data.current_epoch_time;
        if(this.twilioRoomName && this.twilioMeetLink)
        {
          this.container = document.getElementById("video-container");
          this.startRoom()
        }
        else
        {
          this.countDownTimer = "00:00";
          this.appointmentTimeOverModal = true;
        }
        if(successResponse.data.dependent_flag){
          this.age = this.getAge(this.patientDetails.dependent_dob);
        }
        else{
          this.age = this.getAge(this.patientDetails.customer_dob);
        }
        this.prescriptionDetails = successResponse.data.prescription_data.docs;
        this.isLoading = false;
        if(successResponse.data.appointment_prescription_data.length > 0)
        {
          presc_dets = successResponse.data.appointment_prescription_data[0];
          this.selected = presc_dets['covid_related_symptoms'];
          this.allergies = presc_dets['allergies'];
          this.diagnosed_with = presc_dets['diagnosed_with'];
          this.current_taking_medicines = presc_dets['current_taking_medicines'];
          this.suggestions = presc_dets['suggestion'];
          if(presc_dets['need_follow_up'] == true)
            this.need_follow_up = "true"
          this.exams = presc_dets['examination_to_be_done'];
          if(presc_dets['patient_no_show'] == true)
            this.patient_no_show = presc_dets['patient_no_show'];
          if(presc_dets['referred_to_specialist'] == true)
            this.referred_to_specialist = "true";
          if(presc_dets['unstable_connectivity'] == true)
          {
            this.unstable_connectivity = "true";
          }
          if(presc_dets['prescription'].length > 0)
            this.prescription_object = presc_dets['prescription'];
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.followUpDate = presc_dets.next_followup_date;
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
        .then((readAllSpecializationsResponse) => {
          var spec_list = readAllSpecializationsResponse.data.data;
          this.specialization_list.push({'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"});
          this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
          spec_list.forEach((x) =>{
            this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
          });
                    let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
          if(obj)
          {
            this.selected_specialization = obj;
          }
          else if(successResponse.data.appointment_prescription_data.length > 0)
          {
            this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
            this.others = presc_dets.referredSpecialistName;
          }
        })
        .catch((readAllSpecializationsError) =>{
        });
        var user_config_body ={
          appointment_reference_number : this.appointment_reference_number,
          appointment_id : successResponse.data.appointment_data._id,
          meeting_link : successResponse.data.appointment_data.meeting_link,
          config_type : "VIDEO_CALL",
          user_type : "DOCTOR",
          userId : this.doctor_id,
          user_name : this.currentUserName
        }
      })
      .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'doctorVideoCallPageTwilio', 'DoctorLogin', 'doctorToken');
      });
}
},

methods: {
  async switchCamera(room) {
      const cameraTrack = this.connectTrack.find(track => track.kind === 'video');
      this.currentFacingMode = this.currentFacingMode === "user" ? "environment" : "user"
      cameraTrack.restart({ facingMode: this.currentFacingMode });
      // const localParticipant = this.activeRoom.localParticipant;
      // localParticipant.videoTracks.forEach((trackPublication) => {
      //   let newVideoDevicelabel = trackPublication.track.mediaStreamTrack.label
      //   let sampleData = trackPublication.track.mediaStreamTrack
      //   let all_devices = "";
      //   let newDeviceData = this.deviceIDList.find( obj => obj.name !=  newVideoDevicelabel )
      //   this.deviceID = newDeviceData.deviceID;
      //   trackPublication.unpublish();
      //   trackPublication.track.stop();
      //   trackPublication.track.detach();
      // });
      // // {deviceId : {exact: this.deviceID}},

      // //rotate through list index
      // this.currentCamIndex = (this.currentCamIndex + 1) % this.deviceIDList.length;
      // var currentDeviceID = this.deviceIDList[this.currentCamIndex].deviceID;
      // Twilio.createLocalVideoTrack( {deviceId : {exact: currentDeviceID}},{ audio: true, video: { width: 100,height: 125}, name:this.currentUserName}).then(function(localVideoTrack) {
      //     // const participantDiv = document.getElementById(localParticipant.identity);
      //     // participantDiv.remove()
      //     // var children = participantDiv.children;
      //     // for (var i = 0; i < children.length; i++) {
      //     //   var tableChild = children[i];
      //     //   console.log(tableChild.tagName);
      //     //   if(tableChild.tagName == 'VIDEO')
      //     //   {
      //     //     tableChild.remove();
      //     //   }
      //     // }
      //     localParticipant.publishTrack(localVideoTrack);
      // }.bind(this))
    },
  endTwilioVideoCall()
  {
    if(this.activeRoom)
    {
      this.activeRoom.disconnect();
      this.leaveLogEvent("Leave")
      clearInterval(this.intervalOne);
      this.call_ended = true;
    }
  },
  submit_prescription(){
      this.presc_dialog = true;
    },
  async startRoom(){
      const room = await this.joinVideoRoom(this.twilioRoomName, this.twilioMeetLink);
      this.activeRoom = room;
      this.handleConnectedParticipant(room.localParticipant,"local");
      room.participants.forEach(this.handleConnectedParticipant);
      room.on("participantConnected", this.handleConnectedParticipant);
      room.on("participantDisconnected", this.handleDisconnectedParticipant);
      room.on('disconnected', room => {
          // Detach the local media elements
          room.localParticipant.tracks.forEach(publication => {
            publication.track.stop();
            const attachedElements = publication.track.detach();
            console.log("unsubscribed from: " + publication.track)
            attachedElements.forEach(element => element.remove());
          });
      });
      this.callConnected = true;
      navigator.mediaDevices.enumerateDevices().then((cams) =>{
          cams.forEach(mediaDevice => {
            if (mediaDevice.kind === 'videoinput') {
              this.deviceIDList.push({name:mediaDevice.label, deviceID: mediaDevice.deviceId})
            }
          })
        });
      // this.timerOne();
      this.joinLogEvent("Join");
  },
  handleConnectedParticipant(participant,type){
    console.log("Handel Connected Participants")
      const participantDiv = document.createElement("div");
      participantDiv.setAttribute("id", participant.identity);
      this.container.prepend(participantDiv);
      if(type != "local"){
        this.remoteParticipant = participant
      }

      participant.tracks.forEach((trackPublication) => {
          this.handleTrackPublication(trackPublication, participant, type);
      });

      participant.on("trackPublished", this.handleTrackPublication);
  },
  handleTrackPublication(trackPublication, participant, type){
    console.log("Handel Track Publication", trackPublication, participant, type)
      function displayTrack(track, type){
        console.log("Displat Track Function")
          var participantDiv = document.getElementById(participant.identity);
          participantDiv.style.transform = "scale(-1,1)";
          participantDiv.append(track.attach());
          if(type != "local")
          {
            for(var i = 0; i < participantDiv.childElementCount; i++)
            {
              if(participantDiv.children[i].tagName == 'VIDEO')
                participantDiv.children[i].style.height = "70vh";
            }
          }
          else if (type == "local")
          {
            for(var i = 0; i < participantDiv.childElementCount; i++)
            {
              if(participantDiv.children[i].tagName == 'VIDEO')
              {
                participantDiv.children[i].style.position = "absolute";
                participantDiv.children[i].style.bottom = "70px";
                participantDiv.children[i].style.right = "0px";
                participantDiv.children[i].style.zIndex = "999";
                participantDiv.children[i].style.height = "10vh";
              }
            }
          }
      }
      if(!participant){
        console.log("Rparticipant", participant)
        participant = this.remoteParticipant

        const CurrentparticipantDiv = document.getElementById(participant.identity);
        // CurrentparticipantDiv.remove();
        var children = CurrentparticipantDiv.children;
        for (var i = 0; i < children.length; i++) {
          var tableChild = children[i];
          if(tableChild.tagName == 'VIDEO')
          {
            tableChild.remove();
          }
        }

        // const NewparticipantDiv = document.createElement("div");
        // NewparticipantDiv.setAttribute("id", participant.identity);
        // this.container.prepend(NewparticipantDiv);
      }
      if (trackPublication.track) {
          displayTrack(trackPublication.track, type);
      }

      trackPublication.on("subscribed", displayTrack);
  },
  handleDisconnectedParticipant(participant){
    console.log("Handel Disconnected")
    console.log("Remove", participant )
      participant.removeAllListeners();
      const participantDiv = document.getElementById(participant.identity);
      participantDiv.remove();
  },
  async joinVideoRoom(roomName, token){
    const tracks = await Twilio.createLocalTracks({
        audio: true,
        video: { width : 425, height: 300, facingMode:this.currentFacingMode },
    });
    this.connectTrack = tracks
      const room = await Twilio.connect(token, { room: roomName, tracks });
      return room;
  },
  ViewFile(filename)
  {
    var name_arr = filename.split("/");
    var name = name_arr[name_arr.length -1];
    var read_body = {
      file_name: name,
      token : this.currentUser,
      typeOfUser : "DOCTOR",
    };
    axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
    .then((readSuccess) =>{
      //window.open(readSuccess.data.data,'_blank');
      var url = readSuccess.data.data;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(myBlob);
          link.download = name;
          link.click();
        }
      };
      xhr.send();
    })
    .catch((readError) =>{
      console.log(readError);
      window.alert("Error while reading the file");
    });
  },
  closePrescriptionDialogue(){
    this.prescriptionDialog = false;
    this.view_prescription_object = "";
    this.view_selected_prescription = "";
  },
  leaveLogEvent(event){
      if(event === "Leave")
      {
         var leaveLogEventBody={

              bookingId : this.booking_id,
               userType: "DOCTOR",
              token : this.currentUser,
              typeOfUser : "DOCTOR"
         }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/exitCallLogEntry",leaveLogEventBody)
          .then((exitCallLogEntryResponse)=>{

          })
          .catch((exitCallLogEntryError)=>{

        });

      }
  },
  joinLogEvent(event){
      if(event === "Join")
      {
         var JoinLogEventBody={

              bookingId : this.booking_id,
               userType: "DOCTOR",
              token : this.currentUser,
              typeOfUser : "DOCTOR"
         }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/joinCallEntry",JoinLogEventBody)
          .then((joinCallEntryResponse)=>{

          })
          .catch((joinCallEntryError)=>{
        });
      }
  },
  closeExitModal(){
    this.exitModal = false;
     this.$router.push({
      name: "ViewAppointmentDoctor",
    });
  },
  redirectToViewAppointment(){
    this.exitModal = false;
  },
  sleep(booking_id,token,router){
   setTimeout(function(){
     var changeAppointmentStatusOnCompletiondata={
      bookingId : booking_id,
      token : token,
      typeOfUser : "DOCTOR"
    }
    axios.post(process.env.VUE_APP_BACKEND_URL + "/changeAppointmentStatusOnCompletion",changeAppointmentStatusOnCompletiondata)
    .then((changeAppointmentStatusOnCompletionSuccess)=>{
      this.$router.push({
        path:'/doctor/viewappointment/' + booking_id
      })
    })
    .catch((changeAppointmentStatusOnCompletionError)=>{
     router.push({
      path:'/doctor/viewappointment/' + booking_id
    })
   });
  },5000);
  },
  timerOne(){
  var startTime = new Date(this.appointmentDetails.booking_epoch_time).getTime();
  var currentTime = new Date().getTime();
  if(startTime - currentTime > 600000){
    this.showTimer = true;
    // this.countDownTimer = "";
  }
  if(this.showTimer){
    var countDownDate = new Date(this.appointmentDetails.booking_closing_epoch_time).getTime() ;
    var today= new Date(this.current_epoch_time).getTime();
     this.intervalOne = setInterval(() =>{
      today = today + 1000
      var distance = countDownDate - today;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.countDownTimer = String(minutes).padStart(2,0)+":"+ String(seconds).padStart(2,0);
      if(distance < 0){
        clearInterval(this.intervalOne);
        this.countDownTimer = "00:00";
        this.appointmentTimeOverModal = true;
        this.endTwilioVideoCall();
        // this.sleep(this.booking_id,this.currentUser,this.$router);
         //this.timerTwo();
      }
    },1000);
  }
  else{
    this.appointmentStartMessageModal = true;
  }
  },
  timerTwo(){
  this.executeSecondTimer = true;
  var endTime = this.appointmentDetails.time_slot.split("-")[1]
  var bufferTime = Number(endTime.split(":")[1]) + 3;
  var combinedTime = endTime.split(":")[0] + ":" + bufferTime
  var countDownDate = new Date(this.appointmentDetails.date_of_appointment + " " +String(combinedTime)+":00 GMT+0630").getTime() ;
  var  today= new Date().getTime();
  var distance = countDownDate - today;
  var intervalTwo = setInterval(() =>{
    distance = distance - 1000;
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    this.countDownTimer = String(minutes.padStart(2,0))+":"+ String(seconds.padStart(2,0));
    if(distance < 0){
    clearInterval(intervalTwo);
    this.countDownTimer = "00:00";
    this.appointmentTimeOverModal = true;
    this.executeSecondTimer = false;
  }
  },1000);
  },
  save_prescription() {
        this.buttonDisableFlag = true;
    var DateInDateformat = new Date(this.dateOfAppointment)
        if(this.come_after == "One Week"){
          DateInDateformat.setDate(DateInDateformat.getDate()+7)
          this.followUpDate = DateInDateformat.toISOString().substr(0,10);
        }
        else if(this.come_after == "Two Week"){
          DateInDateformat.setDate(DateInDateformat.getDate()+14)
          this.followUpDate = DateInDateformat.toISOString().substr(0,10);
        }
        else if(this.come_after == "One Month"){
            DateInDateformat.setMonth(DateInDateformat.getMonth()+1)
          this.followUpDate = DateInDateformat.toISOString().substr(0,10);

        }
        else if(this.come_after == "Two Month"){
            DateInDateformat.setMonth(DateInDateformat.getMonth()+2)
          this.followUpDate = DateInDateformat.toISOString().substr(0,10);
        }
        else if(this.come_after == "Three Month"){
            DateInDateformat.setMonth(DateInDateformat.getMonth()+3)
          this.followUpDate = DateInDateformat.toISOString().substr(0,10);
        }
    var presc_body = {
      covidRelatedSymptoms: this.selected,
      allergies: this.allergies,
      diagnosedWith: this.diagnosed_with,
      currentMedication: this.current_taking_medicines,
      suggestion: this.suggestions,
      examinationTBD: this.exams,
      followUp: this.need_follow_up,
      patientNoShow: this.patient_no_show,
      referredSpecialist: this.referred_to_specialist,
      unstableConnection: this.unstable_connectivity,
      appointmentReferenceNo: this.appointment_reference_number,
      doctorName: this.currentUserName,
      doctorId: this.doctor_id,
      customerName: this.nameOfPatient,
      customerId: this.patientDetails._id,
      appointmentDate: this.dateOfAppointment,
      specialization: this.specialization,
      break_here_for_newtemplate_variable: "",
      presentSymptoms: this.presenting_symptoms,
      medicalHistory: this.medical_history,
      surgicalHistory: this.surgical_history,
      familyHistory: this.family_history,
      currentMedication: this.current_taking_medicines,
      allergies: this.allergies,
      vaccinationHistory: this.vaccination_history,
      diagnosedWith: this.diagnosed_with,
      investigationNeeded: this.investigations_needed,
      comeAfter: this.come_after ? this.come_after : "Not Required",
      followUpDate: this.followUpDate ? this.followUpDate : "",
      referredSpecialistName: this.selected_specialization.specialization_name != 'Others' ? this.selected_specialization.specialization_name : this.others || '',
      token : this.currentUser,
      typeOfUser : "DOCTOR"
    }
        var prescriptions = [];
    this.prescription_object.forEach(function(item, index, object) {
      if (item.medicine !== '') {
        prescriptions.push(item);
      }
    });
    if(prescriptions.length == 0)
      presc_body.prescription = [];
    else if(prescriptions.length > 0)
      presc_body.prescription = prescriptions;
    axios.post(process.env.VUE_APP_BACKEND_URL+"/createPrescription",presc_body)
    .then((createPrescriptionResponse) =>{
      this.exitModal = true
      this.buttonDisableFlag = false;
      //window.alert("Prescription Saved");
    })
    .catch((createPrescriptionError) =>{
      this.buttonDisableFlag = false;
      if(createPrescriptionError.response.status == 403)
        window.alert("Cannot update a blank prescription");
    });
  },
  add_rec() {
    this.prescription_object.push({'medicine': '', 'dosage': '', 'frequency': '', 'duration': ''});
  },
  remove_rec(medicine,dosage,frequency,duration) {
    this.prescription_object.forEach(function (item, index, object) {
      if (item.medicine === medicine && item.frequency === frequency && item.dosage === dosage && item.duration === duration)
      {
        object.splice(index, 1);
      }
    });
  },
  gotoLoginPage() {
    var deleteData = {
      idToken: this.currentUser,
    };
    axios
    .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
    .then((deleteResponse) => {
      this.$cookies.remove("doctorToken");
    })
    .catch((error) => {
    });
    this.$router.push({
      name: "DoctorLogin",
    });
  },
  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },
  viewPrescription(prescriptionID) {
    this.prescriptionId = prescriptionID;
    // let routeData = this.$router.push({path: "/doctor/viewPrescription/"+ prescriptionID});
    var prescription_body = {
      "prescriptionId":prescriptionID,
      "token" : this.currentUser,
      "typeOfUser" : "DOCTOR"
    };
    axios.post(process.env.VUE_APP_BACKEND_URL+"/readParticularPrescription",prescription_body)
    .then((readParticularPrescriptionResponse) =>{
      this.view_prescription_object = readParticularPrescriptionResponse.data.data;
      let obj = this.specialization_list.find(o => o['specialization_name'] === this.view_prescription_object.referredSpecialistName);
      if(obj)
      {
        this.view_selected_prescription = obj;
      }
      else if(this.view_prescription_object)
      {
        this.view_selected_prescription = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
        this.view_prescription_object.others = this.view_prescription_object.referredSpecialistName;
      }
    })
    .catch((readParticularPrescriptionError) =>{
      console.log(readParticularPrescriptionError);
      window.alert("Something went wrong");
    });
    this.prescriptionDialog = true;
  },
  closeDialogAppointmentTimeOverModal(){
    // this.$router.push({
    //   path:'/doctor/viewappointment/' + this.booking_id
    // });
    this.appointmentTimeOverModal = false;
    this.call_ended = true;
  },
  closeDialogAppointmentStartMessageModal(){
    this.$router.push({
      path:'/doctor/viewappointment/' + this.booking_id
    });
  },
  handleError(error) {
    if (error) {
      alert(error.message);
    }
  },
  turnAudioOff() {
      //const unmuteAudio = document.getElementById('unmuteAudio');
      this.activeRoom.localParticipant.audioTracks.forEach(track => {
      track.track.disable();
      this.audioBtnFlag =  false;
      });
  },
  turnAudioOn() {
    //const unmuteAudio = document.getElementById('unmuteAudio');
    this.activeRoom.localParticipant.audioTracks.forEach(track => {
    track.track.enable();
    this.audioBtnFlag = true;
    });
  },
  turnVideoOff() {
    this.activeRoom.localParticipant.videoTracks.forEach(track => {
      track.track.disable();
      this.videoBtnFlag =  false;
    });
  },
  turnVideoOn(){
    this.activeRoom.localParticipant.videoTracks.forEach(track => {
      track.track.enable();
      this.videoBtnFlag =  true;
    });
  },
},
};
</script>

<style scoped>
.PrescriptionTableMobileLayout{
  display: none;
}
.tabelClass{
  display: block;
}
.mobileTabelClass{
  display: none;
}
.docVideoCallMobilePatientCard{
  display: none;
}
whereby-embed{
  overflow:hidden;height:80vh;width:125%
}
.fieldset_class
{
  border-right-style: none;
  border-left-style: none;
  border-top-style: none;
  border-bottom-style: none;
}
.textfield_class {
  box-sizing: border-box;
  border-radius: 8px;
}
.medicinetextfield_class{
    box-sizing: border-box;
  border-radius: 8px;
}
.timerClass{
   position: absolute;
   margin-left : 30px;
   margin-top: 10px;
   font-size:150%;
}
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

    /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey !important;
  }
.pub_class {
position: absolute;
z-index: 999;
bottom:  20%;
left:  1%;
margin:  5%;
}
.sub_class {
position: relative;
z-index: 0;
width:  100%;
height:  80vh;
margin:  5%;
}
.timmerText{
position: absolute;
border:2px;
background-color: #010101;
color: white;
padding: 5px;
z-index:999;
opacity: 0.5;
border-radius: 0px 0px 0px 4px;
font-size: 20px;
}
.remote_video_container {
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: scroll;
  padding-top:  30px;
}
#localTrack video {
  /*width: 425px !important;*/
  background-repeat: no-repeat;
  height: 800px;

}
@media (max-width: 600px){
.PrescriptionTableMobileLayout{
  display: block;
}
.prescriptionTableClass{
  display: none;
}
.docVideoCallNavbar{
  display: none;
}
.videoAndPrescriptionContainer{
  max-width: 100% !important;
  margin-top:20%;
  height: 100vh;
}
.remote_video_container{
  /* height: 90vh;
  max-height: 90vh; */
  width: 425px;
}
.PrescriptionContainer{
  width: 100vw !important;
  max-width: 100%;
  flex: 0 0 100% !important;
  overflow: unset !important;
}
.DocVideoCalltitleClass{
  display: none;
}
.patientInfoCard{
  display: none;
}
.docVideoCallMobilePatientCard{
  display: block;
}
.docVideoCallTab{
  display: none;
}
.persistingSymptomsClass{
  max-width: unset;
  flex: unset;
  padding:unset;
}
.docVideoCallMedicalDetails{
  max-width: unset;
  flex: unset;
  text-align: left;
}
.docVideocallTreatmentTitle{
  max-width: unset;
  flex: unset;
  text-align: left;
}
.medicinetextfield_class{
  width:150px
}
.addRemoveButton{
  display: none;
}
.tableCols{
  padding: 0px;
}
.mobileTabelClass{
  display: block;
}
.presentingSysmptoms{
  padding-left:12px ;
}
.v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none !important;
}
.saveBtnClass{
  margin-bottom: 15px;
}
.attachmentsTitleClass{
  text-align: left;
}
.attachmentDataClass{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
}
</style>
